<template>
  <header class="mobile-header">
    <div class="top">
      <div class="report-details">
        <h1>{{ dashboardDetails?.name }}</h1>
      </div>

      <button
        class="button is-light sidebar-toggle"
        @click="openSidebar">
        <i class="material-icons">filter_alt</i>
      </button>
    </div>
    <div class="bottom">
      <div
        v-if="dashboardDetails?.key"
        class="filter-section">
        <div
          v-for="requiredFilter in dashboardDetails.required_filters"
          :key="requiredFilter.id"
          class="filter">
          <location-filter
            v-model="dataContext"
            :dashboard-key="dashboardDetails.key"
            :filter-key="requiredFilter.key" />
        </div>
        <div class="filter">
          <time-range-filter v-model="dataContext" />
        </div>
        <div
          v-for="filter in dashboardDetails.dashboard_controls"
          :key="filter.id"
          class="filter">
          <DashboardBenchmarkFilter
            v-if="filter.type === 'BenchmarkSelect'"
            v-model="dataContext"
            :filter-meta="filter" />
          <DashboardControlFilters
            v-else
            v-model="dataContext"
            :filter-meta="filter" />
        </div>
      </div>
      <Drawer
        :is-visible.sync="isSidebarOpen"
        is-full-screen>
        <template #header>
          <h3>Filters</h3>
        </template>
        <DashboardOptionalFilters
          v-model="dataContext"
          :optional-filters="dashboardDetails?.optional_filters"
          :dashboard-key="dashboardDetails?.key" />
      </Drawer>
    </div>
    <DashboardTabs
      :value="selectedTab"
      class="dashboard-tabs-section"
      :tabs="tabOptions"
      @input="$emit('updateTab', $event)" />
  </header>
</template>

<script>
  import DashboardControlFilters from './filters/DashboardControlFilters.vue'
  import LocationFilter from './filters/LocationFilter.vue'
  import TimeRangeFilter from './filters/DashbaordTimeRangeFilter.vue'
  import DashboardOptionalFilters from './filters/DashboardOptionalFilters.vue'
  import Drawer from '@/components/ui/Drawer.vue'
  import DashboardBenchmarkFilter from './filters/DashboardBenchmarkFilter.vue'
  import DashboardTabs from '../components/DashboardTabs.vue'


  export default {
    components: {
      TimeRangeFilter,
      DashboardOptionalFilters,
      LocationFilter,
      DashboardControlFilters,
      Drawer,
      DashboardBenchmarkFilter,
      DashboardTabs,
    },
    props: {
      dashboardDetails: {
        type: Object,
        default: () => {},
      },
      value: {
        type: Object,
        default: () => {},
      },
      selectedTab: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        customFiltersDropdown: false,
        isSidebarOpen: false,
      }
    },
    computed: {
      tabOptions () {
        return (
          this.dashboardDetails?.dashboard_sections?.map((section) => ({
            id: section?.id,
            label: section?.name,
            icon: section?.icon,
          })) || []
        )
      },
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
      appliedOptionalFiltersLabel () {
        const selectedFilters = Object.keys(this.dataContext.filter_selections)
        if (selectedFilters.length === 0) {
          return 'None'
        }
        return this.dashboardDetails.optional_filters
          .filter((item) => selectedFilters.includes(item.key))
          .map((filter) => filter.name)
          .join(', ')
      },
    },
    methods: {
      openSidebar () {
        this.isSidebarOpen = true
      },
    },
  }
</script>

<style lang="scss" scoped>
.mobile-header {
  background-color: white;
}

::v-deep .filter-button {
  border-radius: 6px !important;
  width: 100%;
  cursor: pointer;
  background-color: #fff !important;
  &:hover {
    box-shadow: none !important;
    border: 1px solid #7e7d84 !important;
    background-color: #fff !important;
  }
}

.sidebar-toggle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 36px;
  padding: 0;
  // border: 1px solid $gray-1;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;

  .icon {
    font-size: 14px;
    color: $grayscale-2;
  }

  &:hover {
    background-color: $grayscale-7;
  }
}

.top {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-details h1 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin: 0;
}


.filters {
  padding: 16px;
  background-color: $grayscale-7;
}

.filter-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.bottom {
  padding: 10px;
  background-color: $grayscale-7;
}

.icon {
  color: #666;
}
</style>
