import axios from 'axios'
import { getAuthToken } from '@/service/auth/authService'
import { extractDate } from '@/utils/Date'

export async function getReport (spec, company_id, cancelToken) {
  spec.context_vars = {
    run_date: {
      type: 'const',
      data_type: {
        key: 'date',
      },
      value: extractDate(new Date()),
    },
  }
  return (await axios.post(
    `${process.env.VUE_APP_API_URL}/company/${company_id}/report`,
    spec, { headers: { Authorization: getAuthToken(), }, cancelToken, })
  ).data
}

export async function getReportCsv (spec, company_id) {
  spec.context_vars = {
    run_date: {
      type: 'const',
      data_type: {
        key: 'date',
      },
      value: extractDate(new Date()),
    },
  }
  return (await axios.post(
    `${process.env.VUE_APP_API_URL}/company/${company_id}/reportCsv`,
    spec, { headers: { Authorization: getAuthToken(), }, })
  ).data
}
