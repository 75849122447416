<template>
  <div class="other-filter">
    <div class="dropdown-filter">
      <filter-multiselect
        v-model="selectedValues"
        track-by="key"
        label="label"
        :disabled="loading"
        :mobile-modal="false"
        :top-left-label="filterMeta?.name"
        :loading="loading"
        :options="dropdownOptions">
        <template #action-buttons>
          <div class="quick-action-buttons">
            <b-button
              class="quick-action-button"
              type="is-text"
              :disabled="loading"
              @click.stop="filterChange(null)">
              All
            </b-button>
            <b-button
              class="quick-action-button"
              type="is-text"
              :disabled="loading"
              @click.stop="filterChange([])">
              None
            </b-button>
          </div>
        </template>
      </filter-multiselect>
    </div>
  </div>
</template>

<script>
  import filterMultiselect from '@/components/ui/multiselect'

  export default {
    name: 'CustomFilter',
    components: {
      filterMultiselect,
    },
    mixins: [],
    props: {
      filterMeta: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
      value: {
        type: Array,
        required: false,
        default: null,
      },
      fulldataContext: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        loading: false,
        dropdownOptions: [],
      }
    },
    computed: {
      selectedValues: {
        get () {
          return this.value === null ? this.dropdownOptions : this.value || []
        },
        set (newValue) {
          this.$emit(
            'input',
            newValue?.length === this.dropdownOptions?.length ? null : newValue
          )

          if (!this.loading) {
            this.$emit('makeInputsDirty')
          }
        },
      },
    },
    watch: {},
    async mounted () {
      this.loading = true
      const options = await this.$apis.companyConfigs.getDashboardFilterOptions(
        this.dashboardKey,
        this.filterMeta.key,
        this.fulldataContext
      )

      this.dropdownOptions = options || []
      this.selectedValues = this.value
      await this.$nextTick()
      this.loading = false
    },

    methods: {
      filterChange (values) {
        this.selectedValues = values
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .dropdown-menu {
  .dropdown-content {
    margin-top: 4px !important;
    border-radius: 6px !important;
  }
}

::v-deep .filter-button {
  &.filtered {
    border-color: #994095 !important;
    .top-left-label {
      color: #994095 !important;
    }
    .button-content-bottom {
      .button-value, .dropdown-icon {
        color: $grayscale-1 !important;
      }
    }
  }

  .quick-action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 100%;
  }
  .quick-action-button {
    font-size: 11px;
    color: #504f54 !important;
    padding: 0 !important;
    font-weight: 300;
    border-radius: 6px;
    box-shadow: none !important;
    height: 20px !important;
    padding: 0px 5px !important;
    background-color: $grayscale-7 !important;
  }
}

.dropdown-content {
  border-radius: 4px !important;
}
</style>
