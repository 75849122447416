import Vue from 'vue'

const breakpoints = {
  sm: 960,
  md: 1200,
  lg: 1400,
  xl: 1600,
}

const Screen = {
  install (Vue) {
    const screen = Vue.observable({
      width: window.innerWidth,
      height: window.innerHeight,

      is (size) {
        switch (size) {
          case 'sm':
            return this.width < breakpoints.sm
          case 'md':
            return this.width >= breakpoints.sm && this.width < breakpoints.md
          case 'lg':
            return this.width >= breakpoints.md && this.width < breakpoints.lg
          case 'xl':
            return this.width >= breakpoints.lg
          default:
            return false
        }
      },

      get () {
        if (this.width < breakpoints.sm) {
          return 'sm'
        }
        if (this.width < breakpoints.md) {
          return 'md'
        }
        if (this.width < breakpoints.lg) {
          return 'lg'
        }
        return 'xl'
      },
    })

    Vue.prototype.$screen = screen

    window.addEventListener('resize', () => {
      screen.width = window.innerWidth
      screen.height = window.innerHeight
    })
  },
}

Vue.use(Screen)

export default Screen
