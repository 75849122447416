<template>
  <div class="other-filter">
    <div class="dropdown-filter">
      <singleSelect
        v-model="selectionModel"
        track-by="key"
        :top-left-label="filterMeta?.name"
        label="label"
        :disabled="loading"
        :loading="loading"
        :options="dropdownOptions"
        @input="updateSelectionModel" />
    </div>
  </div>
</template>

<script>
  import singleSelect from '@/components/ui/singleselect'

  export default {
    name: 'DashboardControlFilters',
    components: {
      singleSelect,
    },
    mixins: [],
    props: {
      filterMeta: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectionModel: null,
        loading: false,
        dropdownOptions: [],
      }
    },
    computed: {
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
    },
    watch: {},
    async mounted () {
      this.dropdownOptions = this.filterMeta.options
      this.updateSelectionModel(this.dropdownOptions[0])
      this.selectionModel = this.dropdownOptions[0]
    },

    methods: {
      updateSelectionModel (value) {
        this.$set(this.dataContext.control_selections, this.filterMeta.key, {
          type: this.filterMeta.type,
          selected_option: value.key,
        })
      },
    },
  }
</script>
<style lang="scss" scoped>

</style>
