<template>
  <div class="sub-section">
    <b-field class="">
      <b-switch
        v-model="isEnabled"
        type="is-info">
        Add a pivot to this tracker table
      </b-switch>
    </b-field>

    <template v-if="isEnabled">
      <b-field
        v-if="inputs"
        class="input-field"
        label="Pivot Dimension">
        <CompanyDimensionRefSelect v-model="inputs.dimension" />
      </b-field>

      <div class="columns">
        <div class="column">
          <b-field
            class="input-field"
            label="Order by">
            <b-select
              v-model="orderByTypeSelected"
              placeholder="Select option"
              expanded
              style="width: 100%"
              @input="handleOrderByTypeChange">
              <option
                v-for="option in orderByTypeOptions"
                :key="option"
                :value="option">
                {{ option }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div
          v-if="inputs && inputs.order"
          class="column">
          <b-field
            :class="{'error-border': inputs.order.errors}"
            class="input-field"
            style="margin-top: 22px">
            <b-select
              v-model="orderByKeySelected"
              placeholder="Select option"
              expanded
              style="width: 100%"
              @input="handleOrderByKeyChange">
              <option
                v-for="option in orderByKeyOptions"
                :key="option.key"
                :value="option.key">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>

      <b-field
        v-if="inputs && inputs.order"
        class="input-field"
        label="Pivot Dimension">
        <b-select
          v-model="sortSelected"
          placeholder="Select option"
          expanded
          style="width: 100%"
          @input="handleOrderOptionChange">
          <option
            v-for="option in sortOptions"
            :key="option"
            :value="option">
            {{ option }}
          </option>
        </b-select>
      </b-field>

      <b-field
        v-if="inputs && inputs.order && inputs.order.type === 'MeasureOrder'"
        class="input-field"
        label="Order">
        <b-select
          v-model="inputs.order.time_range"
          placeholder="Select option"
          expanded
          style="width: 100%">
          <option
            v-for="option in timeRangeOptions"
            :key="option.key"
            :value="option.key ? option : null">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <b-field
        class="input-field"
        label="Filter Pivot Values">
        <FilterConfigListEditor v-model="inputs.filters" />
      </b-field>
    </template>
  </div>
</template>

<script>

  import CompanyDimensionRefSelect from '@/components/reports/config/dimensions/CompanyDimensionRefSelect'
  import FilterConfigListEditor from '@/components/reports/config/modules/common/FilterConfigListEditor'

  export default {
    name: 'TrackePivotRows',
    components: {
      CompanyDimensionRefSelect,
      FilterConfigListEditor,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        orderByTypeOptions: ['None', 'DimensionOrder', 'MeasureOrder',],
        sortOptions: ['Ascending', 'Descending',],

        sortSelected: 'Ascending',
        orderByTypeSelected: 'None',
        orderByKeySelected: null,

        timeRangeOptions: [
          {
            key: null,
            name: 'none',
          },

          {
            type: 'CalendarPreset',
            name: 'today',
            key: 'today',
            include_today: false,
          },
          {
            type: 'CalendarPreset',
            name: 'yesterday',
            key: 'yesterday',
            include_today: false,
          },
          {
            type: 'CalendarPreset',
            name: 'WTD',
            key: 'wtd',
            include_today: false,
          },
          {
            type: 'CalendarPreset',
            name: 'QTD',
            key: 'qtd',
            include_today: false,
          },

          {
            type: 'CalendarPreset',
            name: 'YTD',
            key: 'ytd',
            include_today: false,
          },
        ],
      }
    },
    computed: {
      inputs: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      isEnabled: {
        get () {
          return this.inputs !== null
        },
        set (enabled) {
          if (enabled) {
            this.inputs = {
              filters: [],
            }
          } else {
            this.inputs = null
          }
        },
      },
      orderByKeyOptions () {
        if (this.orderByTypeSelected === 'DimensionOrder') {
          return this.$store.state.temp.companyDimensions
        } else if (this.orderByTypeSelected === 'MeasureOrder') {
          return this.$store.state.temp.companyMeasures
        } else {
          return []
        }
      },
    },
    mounted () {
      if (this.inputs && this.inputs.order) {
        this.sortSelected = this.inputs.order.ascending
          ? 'Ascending'
          : 'Descending'
        this.orderByTypeSelected = this.inputs.order.type

        if (this.orderByTypeSelected === 'DimensionOrder') {
          this.orderByKeySelected = this.inputs.order.dimension
        } else if (this.orderByTypeSelected === 'MeasureOrder') {
          this.orderByKeySelected = this.inputs.order.key
        }
      }
    },

    beforeDestroy () {},

    methods: {
      handleOrderOptionChange (val) {
        this.inputs.order.ascending = val === 'Ascending'
      },

      handleOrderByTypeChange (val) {
        if (val === 'None') {
          this.inputs.order = null
        } else if (val === 'MeasureOrder') {
          this.inputs.order = {
            type: val,
            ascending: true,
            time_range: null,
          }
        }
        else {
          this.inputs.order = {
            type: val,
            ascending: true,
          }
        }
      },

      handleOrderByKeyChange (val) {
        if (this.inputs.order.type === 'DimensionOrder') {
          this.inputs.order.dimension = val
        } else {
          this.inputs.order.measure = val
        }
      },
    },
  }
</script>

<style lang="scss" scoped></style>
