<template>
  <div class="graph-module">
    <div class="graph-header">
      <div
        v-if="dashboardModule.name"
        class="module-name">
        {{ dashboardModule.name }}
      </div>

      <div class="actions">
        <button
          v-if="$screen.is('sm')"
          class="button is-small mr-2"
          @click="isSettingsSidebarOpen = true">
          Options
        </button>
        <ExportButton
          :dashboard-module="dashboardModule"
          :dashboard-data-context="dashboardDataContext"
          :dashboard-key="dashboardKey" />
      </div>
    </div>
    <div class="graph-body">
      <div class="graph-container">
        <template v-if="isLoading">
          <b-skeleton
            height="568px"
            width="100%"
            :animated="true" />
        </template>
        <template v-else>
          <HeatmapGraph
            :measure-label="measureLabel"
            :measure-data-type="measureDataType"
            :data="data"
            :labels="labels"
            :theme="theme"
            :x-axis-labels="xAxisLabels"
            :y-axis-labels="yAxisLabels"
            :origin="originValue"
            :min="minValue"
            :max="maxValue" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import HeatmapGraph from './HeatmapGraph.vue'
  import ExportButton from '../ExportButton.vue'
  import axios from 'axios'

  export default {
    name: 'HeatmapModule',
    components: { HeatmapGraph, ExportButton, },
    props: {
      dashboardModule: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
      isReady: {
        type: Boolean,
        required: true,
      },
    },
    data () {
      return {
        isLoading: false,
        cancelTokenSource: null,
        measureLabel: '',
        benchmarkValue: null,
        chartData: null,
      }
    },

    watch: {
      dashboardDataContext: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
    },

    computed: {
      theme () {
        if (this.chartData) {
          return this.chartData.theme
        }
        return 'BLUE'
      },
      originValue () {
        if (this.chartData) {
          return this.chartData.scale.origin
        }
        return 0
      },
      minValue () {
        if (this.chartData) {
          return this.chartData.scale.min
        }
        return 0
      },
      maxValue () {
        if (this.chartData) {
          return this.chartData.scale.max
        }
        return 0
      },
      measureDataType () {
        if (this.chartData) {
          return this.chartData.measure_type
        }
        return 0
      },
      xAxisLabels () {
        if (this.chartData) {
          return this.chartData.x_axis.map(
            (xValue) => xValue.label
          )
        }
        return []
      },
      yAxisLabels () {
        if (this.chartData) {
          return this.chartData.y_axis.map(
            (yValue) => yValue.label
          )
        }
        return []
      },
      labels () {
        if (this.chartData) {
          let results = this.chartData.x_axis.map((xValue) =>
            this.chartData.y_axis.map((yValue) => {
              let dataCell = this.chartData.data_cells[
                `${xValue.id},${yValue.id}`
              ]
              return {
                long: dataCell?.label,
                short: dataCell?.short_label,
              }
            })
          )
          return results
        }
        return []
      },
      data () {
        if (this.chartData) {
          let results = []
          this.chartData.x_axis.forEach((xValue, xIdx) => {
            this.chartData.y_axis.forEach((yValue, yIdx) => {
              let dataCell = this.chartData.data_cells[
                `${xValue.id},${yValue.id}`
              ]
              results.push([
                xIdx,
                yIdx,
                dataCell?.value,
              ])
            })
          })
          return results
        }
        return []
      },
    },

    mounted () {
      this.loadData()
    },

    beforeDestroy () {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Component destroyed')
      }
    },

    methods: {
      async loadData () {
        if (!this.isReady) {
          return
        }
        try {
          if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('New request initiated')
          }

          this.cancelTokenSource = axios.CancelToken.source()
          this.isLoading = true

          const response = await this.$apis.companyConfigs.getDashboardModuleData(
            this.dashboardKey,
            this.dashboardModule.key,
            this.dashboardDataContext,
            { cancelToken: this.cancelTokenSource.token, }
          )

          if (response?.error) {
            if (response?.code !== 'ERR_CANCELED') {
              this.$buefy.toast.open({
                message: 'Failed to load Heatmap Data',
                type: 'is-warning',
              })
            }
            return
          }

          this.chartData = response
          this.isLoading = false

        } catch (error) {
          console.log(error)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
.graph-module {
  width: 100%;
  height: 100%;
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  flex-direction: column;
  background-color: white;

  @include mobile {
    border-radius: 0px;
  }
}

.graph-header {
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
  color: #333;
  padding: 8px;
  display: flex;
  align-items: center;

  .module-name {
    margin-right: 12px;
  }

  .actions {
    margin-left: auto;
  }
}

.graph-body {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.graph-container {
  height: 600px;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
