<template>
  <div class="location-filter">
    <singleSelect
      v-model="selectionModel"
      track-by="key"
      class="location-filter-select"
      label="label"
      :top-left-label="control.name"
      :disabled="loading"
      :loading="loading"
      :options="dropdownOptions"
      @input="updateSelectionModel" />
  </div>
</template>

<script>
  import singleSelect from '@/components/ui/singleselect'

  export default {
    name: 'LocationFilter',
    components: {
      singleSelect,
    },
    mixins: [],
    props: {
      filterKey: {
        type: String,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectionModel: null,
        loading: false,
        dropdownOptions: [],
      }
    },
    computed: {
      control () {
        return {
          name: 'Location',
        }
      },
      logoUrl () {
        return `${process.env.VUE_APP_S3_BASE_URL}/${this.selectionModel?.logo_fname}`
      },
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
    },
    watch: {},
    async mounted () {
      const response = await this.$apis.companyConfigs.getDashboardFilterOptions(
        this.dashboardKey,
        this.filterKey,
        this.dataContext
      )
      this.dropdownOptions = response || []
      this.selectionModel = this.dropdownOptions[0]
      this.updateSelectionModel(this.dropdownOptions[0])
    },

    methods: {
      updateSelectionModel (value) {
        this.$set(this.dataContext.filter_selections, this.filterKey, [
          value?.key,
        ])

        this.$emit('update-location-logo', value?.logo_fname)
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .dropdown-menu {
  padding-top: 4px !important;
  .dropdown-content {
    border-radius: 6px !important;
  }
  .filter-button {
    border-radius: 6px;
  }
}

.location-filter {
  display: flex;
  width: 100%;
}

.location-filter-select {
  width: 100%;
}

</style>
