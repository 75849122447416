<template>
  <div
    class="table-cell"
    :class="{
      red: cellData?.quality === 'bad',
      green: cellData?.quality === 'good',
      dimension: column.column_type === 'DIMENSION',
      measure: column.column_type === 'MEASURE',
      total: cellData.is_total
    }">
    <i
      v-if="cellData.direction && cellData.direction !== 'none'"
      class="material-icons arrow-icon">
      {{ cellData.direction === "up" ? "arrow_upward" : "arrow_downward" }}
    </i>
    <span class="value">
      {{ cellData.value }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'TableCell',
    props: {
      cellData: {
        type: Object,
        required: true,
      },
      column: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style scoped>
.table-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  border-right: 1px solid #e9e9ea;
  border-bottom: 1px solid #e9e9ea;
  text-align: center;
  padding: 0 10px;
  background-color: #fff;

  &.dimension {
    text-align: left;
  }

  &.total {
    border-bottom: 1px solid #504F54;
    font-weight: 600;
  }
  &.measure {
    justify-content: end;
  }

  &.green {
    color: #008c84;
    background-color: rgba(0, 140, 132, 0.05);
  }
  &.red {
    color: #d31b5e;
    background-color: rgba(211, 27, 94, 0.05);
  }
  .value {
  }
  .arrow-icon {
    font-size: 16px;
    margin-right: auto;
  }
}
</style>
