<template>
  <div class="nav-container">
    <div class="navbar-header">
      <div class="left">
        <img
          src="@/assets/images/ingest_logo.svg"
          class="logo"
          alt="Ingest Logo" />
        <div class="breadcrumbs">
          <b-breadcrumb>
            <b-breadcrumb-item
              v-for="(breadcrumb, i) in breadcrumbs"
              :key="i"
              tag="router-link"
              :active="breadcrumb.routeName ? $route?.name === breadcrumb.routeName : true"
              :to="{ name: breadcrumb.routeName }">
              {{ breadcrumb.displayName }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
      <div class="right">
        <!-- <button
          class="button action-item"
          aria-label="Toggle Search"
          @click="toggleSearch">
          <img
            src="@/assets/icons/search.svg"
            alt="Search Icon" />
        </button> -->
        <button
          class="button action-item hamburger"
          aria-label="Toggle Sidebar"
          @click="toggleSidebar">
          <span />
          <span />
          <span />
        </button>
      </div>
    </div>

    <!-- <quick-search v-model="isSearchOpen" /> -->

    <Drawer
      :is-visible.sync="isSidebarOpen">
      <template #header />

      <div class="sidebar-content p-4">
        <nav>
          <div
            v-if="hasMultipleCompanies"
            class="sidebar-item">
            <company-changer />
          </div>
          <div
            v-else
            class="current-company">
            {{ companyName }}
          </div>
          <template v-for="(navlink, index) in navlinks">
            <div
              v-if="navlink?.showInMobile"
              :key="index"
              class="sidebar-item p-2">
              <router-link
                class="parent-link"
                :to="navlink.route"
                @click.native="closeSidebar">
                {{ navlink.name }}
              </router-link>
              <div
                v-if="navlink.children && childHasPermission(navlink.children)"
                class="sidebar-submenu">
                <router-link
                  v-for="(childLink, j) in navlink.children"
                  :key="j"
                  class="child-link"
                  :to="childLink.route"
                  @click.native="closeSidebar">
                  {{ childLink.name }}
                </router-link>
              </div>
            </div>
          </template>
        </nav>
        <div class="sidebar-item p-2">
          <router-link
            class="parent-link"
            :to="{ name: 'my_profile' }"
            @click.native="closeSidebar">
            My Profile
          </router-link>
        </div>
        <div class="sidebar-spacer" />
        <button
          class="sidebar-item logout-button is-light"
          @click="logout">
          Log Out
        </button>
      </div>
    </Drawer>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CompanyChanger from '@/components/company/CompanyChanger'
  import navigation from '@/mixins/navigation'
  import Drawer from '@/components/ui/Drawer.vue'

  export default {
    name: 'MobileNavBar',
    components: {
      CompanyChanger,
      Drawer,
    },
    mixins: [navigation,],
    data () {
      return {
        routerHierarchy: {},
        isSearchOpen: false,
        isSidebarOpen: false,
      }
    },
    computed: {
      ...mapGetters({
        companyName: 'auth/user/companyName',
      }),
      allRoutes () {
        return this.$router.getRoutes()
      },
      breadcrumbs () {
        let  currentRoute = {
          displayName: this.$route.meta.displayName || this.$route.name,
        }
        const breadcrumbs = []

        if (this.$route.meta.breadcrumbs && this.$route.meta.breadcrumbs.length) {
          this.$route.meta.breadcrumbs.forEach((item) => {
            const route = this.findRouteByName(item)
            if (route) {
              breadcrumbs.push({
                routeName: route.name,
                displayName: route.meta?.displayName || route.name,
              })
            }
          })
        }

        breadcrumbs.push(currentRoute)

        return breadcrumbs
      },
    },
    methods: {
      toggleSearch () {
        this.isSearchOpen = !this.isSearchOpen
      },
      toggleSidebar () {
        this.isSidebarOpen = !this.isSidebarOpen
      },
      closeSidebar () {
        this.isSidebarOpen = false
      },
      findRouteByName (name) {
        return this.allRoutes.find((item) => item.name === name)
      },
      childHasPermission (children) {
        return children.some((child) => child.permission)
      },
      logout () {
        try {
          this.$store.dispatch('auth/logout')
        } catch (e) {
          console.error('Logout error:', e)
        }
        this.$router.push({ name: 'login', })
        this.closeSidebar()
      },
    },
  }
</script>

<style lang="scss" scoped>
$navbar-height: 55px;
$breadcrumbs-height: 40px;


.navbar-header {
  height: $navbar-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background-color: $grayscale-8 !important;
  border-bottom: $border-1;
  width: 100%;
}

.breadcrumbs {
  padding: 0px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #727272;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.left {
  display: flex;
  align-items: center;
  width: 100%;

  .logo {
    height: 32px;
    width: auto;
    margin-right: 10px;
  }

  .current-company {
    font-size: 14px;
    border-radius: 9px;
  }
}

.right {
  display: flex;
  align-items: center;

  .action-item {
    border: none;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    padding: 0;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;

    img {
      width: 21px;
      height: 21px;
    }

    &:hover {
      border-color: $grayscale-3;
    }
  }

  .hamburger {
    flex-direction: column;

    span {
      display: block;
      width: 20px;
      height: 2px;
      background-color: $grayscale-2;
      margin: 2px 0;
    }
  }
}

.sidebar-content {
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .sidebar-item {
    .parent-link {
      display: block;
      color: $grayscale-2;
      font-size: 16px;
      padding: 10px 0;
      text-transform: capitalize;
      transition: color 0.3s ease;
      width: 100%;

      &:hover {
        color: $primary;
      }
    }

    .sidebar-submenu {
      padding-left: 15px;
      width: 100%;

      .child-link {
        display: block;
        color: $grayscale-3;
        font-size: 14px;
        padding: 8px 0;
        text-transform: capitalize;
        transition: color 0.3s ease;
        width: 100%;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .sidebar-spacer {
    flex-grow: 1;
  }

  .logout-button {
    padding: 10px 15px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: auto;
    border: none;
    font-size: 16px;
    width: 100%;

    &:hover {
      background-color: darken($primary, 10%);
    }
  }
}

@media all and (display-mode: standalone) {
  .progressier-install-button {
    display: none !important;
  }
}
</style>
