<template>
  <div
    ref="heatmapChart"
    class="heatmap-chart-container" />
</template>

<script>
  import * as echarts from 'echarts'

  const greenTheme = [
    '#FFFFFF',
    '#E5F3F2',
    '#CCE8E6',
    '#B2DCDA',
    '#99D1CE',
    '#7FC5C1',
    '#66BAB5',
    '#4DAFA9',
    '#33A39D',
    '#1A9891',
    '#008C84',
  ]

  const redTheme = [
    '#FFFFFF',
    '#FAE8EE',
    '#F6D1DF',
    '#F1BACE',
    '#EDA4BF',
    '#E98DAE',
    '#E5769E',
    '#E1608F',
    '#DC497E',
    '#D8326F',
    '#D31B5E',
  ]

  const redGreenTheme = [].concat(
    redTheme.slice().reverse(),
    greenTheme.slice(1)
  )

  const greenRedTheme = redGreenTheme.slice().reverse()

  const blueTheme = [
    '#FFFFFF',
    '#EBEEFC',
    '#D8DFFA',
    '#C4CEF7',
    '#B1BEF5',
    '#9DAEF2',
    '#899EEF',
    '#768EED',
    '#627DEA',
    '#4F6EE8',
    '#3B5DE5',
  ]

  export default {
    name: 'HeatmapChart',
    inject: {
      isContained: {
        default: () => true,
      },
    },
    props: {
      measureLabel: {
        type: String,
        default: '',
      },
      benchmarkValue: {
        type: Number,
        default: null,
      },
      aboveTheme: {
        type: String,
        default: 'green',
      },
      belowTheme: {
        type: String,
        default: 'red',
      },
      theme: {
        type: String,
        default: 'blue',
      },
      data: {
        type: Array,
        required: true,
      },
      labels: {
        type: Array,
        required: true,
      },
      xAxisLabels: {
        type: Array,
        required: true,
      },
      yAxisLabels: {
        type: Array,
        required: true,
      },
      origin: {
        type: Number,
        default: 0,
      },
      min: {
        type: Number,
        default: 0,
      },
      max: {
        type: Number,
        default: 10,
      },
      measureDataType: {
        type: String,
        default: 'number',
      },
    },
    data () {
      return {
        chartInstance: null,
      }
    },
    computed: {
      isContainerExpanded () {
        return this.isContained()
      },
      visualMap () {
        return [
          {
            min: this.min < 0 ? -1 : 0,
            max: this.max > 0 ? 1 : 0,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '15%',
            inRange: {
              color: this.getThemeColors,
            },
            formatter: (value) => {
              if (value < 0) {
                value = value * -1 * this.min
              }
              if (value > 0) {
                value = value * this.max
              }
              if (this.measureDataType === 'money') {
                return '$' + (value / 100).toLocaleString()
              }
              if (this.measureDataType === 'percentage') {
                return (value * 100).toFixed(2) + '%'
              }
              return value
            },
          },
        ]
      },
      getThemeColors () {
        switch (this.theme) {
          case 'RED_GREEN':
            if (this.max <= this.origin) {
              return redTheme.slice().reverse()
            }
            if (this.min >= this.origin) {
              return greenTheme
            }
            return redGreenTheme
          case 'GREEN_RED':
            if (this.max <= this.origin) {
              return greenTheme.slice().reverse()
            }
            if (this.min >= this.origin) {
              return redTheme
            }
            return greenRedTheme
          case 'BLUE':
            return blueTheme
          default:
            return blueTheme
        }
      },
      chartOptions () {
        let labels = this.labels
        return {
          tooltip: {
            position: 'top',
            formatter: function (params) {
              return labels[params.data[0]][params.data[1]].long
            },
          },
          grid: {
            height: '50%',
            top: '10%',
          },
          xAxis: {
            type: 'category',
            data: this.xAxisLabels,
            splitArea: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          yAxis: {
            type: 'category',
            data: this.yAxisLabels,
            splitArea: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              color: '#B2B1B6',
              padding: [0, 15, 0, 0,],
            },
          },
          visualMap: this.visualMap,
          series: [
            {
              name: this.measureLabel,
              type: 'heatmap',
              data: this.data,
              label: {
                show: true,
                formatter: function (params) {
                  return labels[params.data[0]][params.data[1]].short
                },
              },
              itemStyle: {
                borderRadius: 4,
                borderColor: '#fff',
                borderWidth: 2,
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                  borderRadius: 4,
                },
              },
            },
          ],
        }
      },
    },
    watch: {
      isContainerExpanded: {
        handler () {
          this.$nextTick(() => {
            this.resizeChart()
          })
        },
      },
    },
    mounted () {
      this.initChart()
      window.addEventListener('resize', this.resizeChart)
    },
    beforeDestroy () {
      if (this.chartInstance) {
        this.chartInstance.dispose()
      }
      window.removeEventListener('resize', this.resizeChart)
    },
    methods: {
      initChart () {
        this.chartInstance = echarts.init(this.$refs.heatmapChart)
        this.chartInstance.setOption(this.chartOptions)
      },
      resizeChart () {
        if (this.chartInstance) {
          this.chartInstance.resize()
        }
      },
      updateChart () {
        if (this.chartInstance) {
          this.chartInstance.setOption(this.chartOptions)
        }
      },
    },
  }
</script>

<style>
.heatmap-chart-container {
  width: 100%;
  height: 500px;
}
</style>
