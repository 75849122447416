<template>
  <div class="container">
    <div class="title">
      <h2>Reports</h2>
      <!-- <p>
        {{ navlinks.reports.description }}
      </p> -->
    </div>
    <div class="search-container">
      <div class="search-input">
        <b-input
          v-model="reportSearchInput"
          custom-class="no-border no-shadow"
          placeholder="Search..."
          type="search"
          icon="magnify" />
      </div>
    </div>

    <div class="reports-container">
      <template v-if="newReports.length">
        <div class="reports-group">
          <h3 class="group-title">
            Core Reports
          </h3>
          <div
            v-for="(report, i) in newReports"
            :key="i">
            <router-link
              class=""
              :to="report.route">
              <div class="report-card">
                <div class="icon-container">
                  <i class="material-icons item-icon">
                    {{ report.icon }}
                  </i>
                </div>
                <div class="name-container">
                  <div class="name">
                    {{ report.name }}
                  </div>
                  <div class="desc">
                    {{ report.description || "No Description" }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </template>

      <template v-if="deprecatedReports.length && !$screen.is('sm')">
        <div class="reports-group">
          <h3 class="group-title">
            Standard Reports
          </h3>
          <div
            v-for="(report, i) in deprecatedReports"
            :key="i"
            :class="{ 'is-depricated': report.isDepricated }">
            <router-link
              class=""
              :to="report.route">
              <div class="report-card">
                <div class="icon-container">
                  <i class="material-icons item-icon">
                    {{ report.icon }}
                  </i>
                </div>
                <div class="name-container">
                  <div class="name">
                    {{ report.name }}
                  </div>
                  <div class="desc">
                    {{ report.description || "No Description" }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import FuzzySearch from 'fuzzy-search'
  import navigation from '@/mixins/navigation'
  export default {
    name: 'ReportsHome',
    components: {},
    mixins: [navigation,],
    data () {
      return { reportSearchInput: '', }
    },
    computed: {
      searcher () {
        const searcher = new FuzzySearch(this.allReports, ['name',])
        return searcher
      },

      resultsToShow () {
        let results = this.allReports
        if (this.reportSearchInput) {
          results = this.searcher.search(this.reportSearchInput)
        }
        return results
      },

      deprecatedReports () {
        return this.resultsToShow
          .filter((report) => report.isDepricated)
          .sort((a, b) => a.name.localeCompare(b.name))
      },

      newReports () {
        return this.resultsToShow
          .filter((report) => !report.isDepricated)
          .sort((a, b) => a.name.localeCompare(b.name))
      },
    },
  }
</script>
<style lang="scss" scoped>
.container {
  padding: 40px 20px;

  @include mobile {
    padding: 30px 10px 50px;
  }
}

.title {
  h2 {
    font-size: 20px;
    font-weight: 500;
    color: $ui-01;
    margin-bottom: 8px;

    @include mobile {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 300;
    color: $ui-03;
    margin-bottom: 30px;

    @include mobile {
      font-size: 16px;
    }
  }
}

.search-container {
  background-color: white;
  border: $border-1;
  border-radius: 6px;
  padding: 6px;
  margin-bottom: 20px;
}

.search-input {
  border: none;
  border-radius: 6px;
  margin: 0;
}

.reports-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reports-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  .group-title {
    font-size: 16px;
    font-weight: 200;
    color: $grayscale-3;
    margin-bottom: -10px;
    border-bottom: $border-2;
    padding-bottom: 4px;
  }
}

.is-depricated {
  @include mobile {
    display: none !important;
  }
}

.report-card {
  display: flex;
  height: 100%;
  color: $grayscale-1;
  font-size: 14px;
  border: $border-1;
  border-radius: 4px;
  transition: all 0.3s;
  overflow: hidden;

  // @include mobile {
  //   font-size: 16px;
  //   padding: 15px;
  // }

  &:hover {
    background-color: white;
    border-color: $primary;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02);

    .icon-container {
      background-color: $primary;
      .item-icon {
        color: white;
      }
    }
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 12px;
    width: 36px;
    color: #b2b1b6;
    border-right: $border-2;
    transition: color 0.3s;

    .item-icon {
      font-size: 16px;
      color: $primary;
    }

    // @include mobile {
    //   width: 35px;
    //   height: 35px;
    //   margin-right: 10px;
    // }
  }

  .name-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .name {
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    color: #28272b;
    text-transform: capitalize;
    padding: 6px 12px;
    border-bottom: $border-2;

    // @include mobile {
    //   font-size: 18px;
    // }
  }

  .desc {
    font-size: 14px;
    font-weight: 200;
    color: #7e7d84;
    line-height: 18px;
    padding: 6px 12px;

    // @include mobile {
    //   font-size: 16px;
    //   line-height: 20px;
    //   margin-top: 14px;
    // }
  }
}
</style>
