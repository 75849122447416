<template>
  <div class="location-filter">
    <div class="dropdown-filter">
      <time-range-filter
        class="filter-dropdown"
        :hide-icon="true"
        :hide-secondary-label="true"
        :default="computedDefaultTimeRange"
        :show-secondary-label-as-primary="$screen.is('sm')"
        @submit="updateTimeRange">
        <div class="quick-actions">
          <div class="button-label">
            {{ control.name }}
          </div>
          <div class="preset-label is-hidden-mobile">
            {{ timeRangeLabel }}
          </div>
        </div>
      </time-range-filter>
    </div>
  </div>
</template>

<script>
  import TimeRangeFilter from '@/components/controls/filter/TimeRangeFilter.vue'
  export default {
    name: 'DashboardTimeRangeFilter',
    components: {
      TimeRangeFilter,
    },
    mixins: [],
    props: {
      value: {
        type: Object,
        required: true,
      },
      defaultTimeRange: {
        type: String,
        required: false,
        default: 'last_7_days',
      },
    },
    data () {
      return {
        timeRangeLabel: '',
      }
    },
    computed: {
      control () {
        return {
          name: 'Date Range',
        }
      },
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
      computedDefaultTimeRange () {
        switch (this.defaultTimeRange) {
          case 'today':
            return {
              mode: 'calendar',
              calendarPreset: 'today',
            }
          case 'yesterday':
            return {
              mode: 'calendar',
              calendarPreset: 'yesterday',
            }
          case 'wtd':
            return {
              mode: 'fiscal',
              fiscalPreset: 'wtd',
            }
          case 'previous_week':
            return {
              mode: 'fiscal',
              fiscalPreset: 'prev_week',
            }
          case 'last_7_days':
            return {
              mode: 'calendar',
              calendarPreset: 'last_7',
            }
          case 'last_30_days': // New time range filter only
            return {
              mode: 'calendar',
              calendarPreset: 'last_30',
            }
          case 'last_5_weeks':
            return {
              mode: 'calendar',
              calendarPreset: 'last_month',
            }
          case 'qtd':
            return {
              mode: 'fiscal',
              fiscalPreset: 'qtd',
            }
          default:
            return {
              mode: 'calendar',
              calendarPreset: 'last_7',
            }
        }
      },
    },
    watch: {},
    async mounted () {},
    methods: {
      updateTimeRange (data) {
        this.timeRangeLabel = data?.label?.typeLabel
        if (data.mode === 'RANGE') {
          this.$store.dispatch('datePicker/selectRangeStart', data.dates[0])
          this.$store.dispatch('datePicker/selectRangeEnd', data.dates[1])
        } else {
          this.$store.dispatch('datePicker/selectDates', data.dates)
        }
        this.$store.dispatch('datePicker/apply')

        this.dataContext.date_selection.selected_dates = this.$store.getters[
          'datePicker/appliedEffectiveDates'
        ].map((item) => item.date)
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .dropdown-menu {
  padding-top: 4px !important;
  .dropdown-content {
    border-radius: 6px !important;
  }
}

::v-deep .datepicker-button {
  border-radius: 6px;
  padding: 7px 15px;
  background-color: #fff !important;

  &:hover {
    box-shadow: none !important;
    border: 1px solid #7E7D84 !important;
    background-color: #fff !important;
  }
}

.filter-dropdown {
  align-items: center;
  width: 100%;
  display: flex;
}

.quick-actions {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  justify-content: space-between;

  // @include mobile {
  //   width: 180px !important;
  //   min-width: 180px;
  //   max-width: 180px;
  //   border: none;
  //   border-radius: 0;
  // }

  .button-label {
    font-size: 13px;
    color: $grayscale-4;
    font-weight: 300;
    margin-right: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    // @include mobile {
    //   border-radius: 0;
    //   justify-content: left;
    //   width: 300px;
    // }
  }

  .preset-label {
    text-align: left;
    font-size: 13px;
    color: $grayscale-4;
  }
}
</style>
