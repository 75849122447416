<template>
  <div class="other-filter">
    <div
      class="dropdown-filter"
      :class="{
        'has-drilldown': drilldownValues?.length,
      }">
      <singleselect
        v-model="selectionModel"
        track-by="key"
        label="label"
        :top-left-label="filterMeta?.name"
        :disabled="loading"
        :loading="loading"
        :options="dropdownOptions"
        @input="updateSelectionModel">
        <template
          v-if="drilldownValues?.length"
          #action-buttons>
          <div class="quick-action-buttons">
            <b-button
              class="quick-action-button"
              type="is-text"
              @click="clearDrilldown">
              Clear Drilldown
            </b-button>
          </div>
        </template>
        <template #before>
          <div
            v-if="drilldownValues?.length"
            class="drilldown-container">
            <h2 class="pb-2">
              Drilldown
            </h2>

            <div class="drilldown-items">
              <div
                v-for="(item, index) in drilldownValues"
                :key="index"
                class="drilldown-item">
                <div class="drilldown-content">
                  <div class="drilldown-header">
                    <div class="drilldown-title">
                      {{ optionKeyLabelMap[item.option] }}
                    </div>
                    <button
                      class="remove-drilldown"
                      @click.prevent="removeDrilldown(index)">
                      <i class="material-icons">close</i>
                    </button>
                  </div>

                  <div class="drilldown-value">
                    {{ item.value.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </singleselect>
    </div>
  </div>
</template>

<script>
  import singleselect from '@/components/ui/singleselect'

  export default {
    name: 'SectionControlFilters',
    components: {
      singleselect,
    },
    mixins: [],
    props: {
      filterMeta: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectionModel: null,
        loading: false,
        dropdownOptions: [],
      }
    },
    computed: {
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
      drilldownValues () {
        return this.dataContext?.drilldown_path || null
      },
      optionKeyLabelMap () {
        return (
          this.filterMeta.options?.reduce((acc, dv) => {
            acc[dv.key] = dv.label
            return acc
          }, {}) || {}
        )
      },
    },
    watch: {
      drilldownValues: {
        handler (newValue) {
          if (!newValue || newValue.length === 0) {
            this.dropdownOptions = this.filterMeta.options
            this.updateSelectionModel(this.dropdownOptions[0])
          } else {
            // Get array of keys that are in the drilldown path
            const drilldownKeys = newValue.map((dv) => dv.option)
            // Filter options to exclude any that are in drilldown path
            this.dropdownOptions = this.filterMeta.options.filter((option) => {
              return !drilldownKeys.includes(option.key)
            })

            // Update selection if options exist
            if (this.dropdownOptions.length > 0) {
              this.updateSelectionModel(this.dropdownOptions[0])
            }
          }
        },
        immediate: true,
      },
    },
    async mounted () {
      this.dropdownOptions = this.filterMeta.options
      this.updateSelectionModel(this.dropdownOptions[0])
    },

    methods: {
      updateSelectionModel (value) {
        this.selectionModel = value
        this.$set(this.dataContext, 'selected_option', value.key)
      },
      removeDrilldown (index) {
        this.drilldownValues.splice(index, 1)
      },
      clearDrilldown () {
        this.$set(this.dataContext, 'drilldown_path', [])
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .dropdown-menu {
  .dropdown-content {
    margin-top: 4px !important;
    border-radius: 6px !important;
  }
}

.dropdown-filter.has-drilldown {
  ::v-deep .filter-button {
    border-color: #994095 !important;
    .top-left-label {
      color: #994095 !important;
    }

    &:hover {
      box-shadow: none !important;
      border: 1px solid #7e7d84 !important;
      background-color: #fff !important;
    }
  }
}

::v-deep .filter-button {
  border-radius: 6px !important;
  width: 100%;
  cursor: pointer;
  // background-color: #fff !important;
  &:hover {
    box-shadow: none !important;
    border: 1px solid #7e7d84 !important;
    background-color: #fff !important;
  }

  .quick-action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 100%;
  }
  .quick-action-button {
    font-size: 11px;
    color: #504f54 !important;
    padding: 0 !important;
    font-weight: 300;
    border-radius: 6px;
    box-shadow: none !important;
    height: 20px !important;
    padding: 0px 5px !important;
    background-color: $grayscale-7 !important;
  }
}

.drilldown-container {
  padding: 8px;
  border-bottom: 1px solid #e9e9ea;
}
.drilldown-items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.drilldown-item {
  // background: white;
  border: 1px solid #b654b1;
  border-radius: 4px;
  width: 200px;
  flex: 0 0 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .drilldown-content {
    flex: 1;
  }

  .drilldown-header {
    border-bottom: 1px solid #b654b1;
    background: #994095;
    color: white;
    display: flex;

    .drilldown-title {
      font-size: 12px;
      text-transform: capitalize;
      padding: 4px 8px;
    }

    .remove-drilldown {
      margin-left: auto;
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      color: white;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #b654b1;
      padding: 4px 8px;

      i {
        font-size: 16px;
      }

      &:hover {
        background-color: #b654b1;
      }
    }
  }

  .drilldown-value {
    font-size: 14px;
    color: $grayscale-2;
    font-weight: 500;
    padding: 8px;
  }
}

.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &.filtered {
    border: 1px solid #ffb1a0;

    .icon {
      color: #ff6341 !important;
    }

    .button-value {
      color: #ff6341 !important;
    }
  }

  .icon {
    color: $gray-3;
    font-size: 18px !important;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0 15px;
    height: 28px;
    display: flex;
    align-items: center;
    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      max-width: 200px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .loading-message {
      font-size: 13px;
      color: $grayscale-4;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}
</style>
