<template>
  <singleSelect
    v-model="selectionModel"
    track-by="key"
    :no-border="false"
    label="label"
    :top-left-label="filterMeta?.name"
    :top-right-label="selectionModel?.label"
    :header-label="selectionRangeText"
    :disabled="loading"
    :loading="loading"
    :options="validDropdownOptions"
    @input="updateSelectionModel" />
</template>

<script>
  import singleSelect from '@/components/ui/singleselect'
  import * as moment from 'moment'

  export default {
    name: 'DashboardBenchmarkFilter',
    components: {
      singleSelect,
    },
    mixins: [],
    props: {
      filterMeta: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectionModel: null,
        loading: false,
        dropdownOptions: [],

        durations: {
          previous_day: 1,
          previous_week: 7,
          previous_period: 28,
          previous_quarter: 91,
          previous_year: 364,
          benchmark: 0,
        },
      }
    },
    computed: {
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
      validDropdownOptions () {
        return this.dropdownOptions.filter((option) =>
          this.isOffsetViable(option.key)
        )
      },
      benchmarkDateRange () {
        const selectedDates = this.dataContext?.date_selection?.selected_dates
        const selectedOption = this.selectionModel?.key
        if (
          selectedDates.length > 0 &&
          this.selectionModel?.key &&
          this.durations[selectedOption]
        ) {
          const offsetDays = this.durations[selectedOption] || 0

          const startDate = moment(selectedDates[0])
            .subtract(offsetDays, 'days')
            .format('ll')
          const endDate = moment(selectedDates[selectedDates.length - 1])
            .subtract(offsetDays, 'days')
            .format('ll')
          return { startDate, endDate, }
        }
        return { startDate: null, endDate: null, }
      },
      selectionRangeText () {
        if (!this.selectionModel) return ''

        const startDate = this.benchmarkDateRange.startDate
        const endDate = this.benchmarkDateRange.endDate

        if (!startDate) return ''

        return `${startDate} ${
          endDate && endDate != startDate ? ` to ${endDate}` : ''
        }`
      },
    },
    watch: {},
    async mounted () {
      this.dropdownOptions = [...this.filterMeta.options,]
      let defaultOption = this.dropdownOptions.find(
        (o) => o.key === this.filterMeta.default_option
      )
      // If default is not found, use first available option
      defaultOption = defaultOption || this.validDropdownOptions[0]
      // Set default selection only if there's no user selection
      if (!this.dataContext.control_selections[this.filterMeta.key]) {
        this.updateSelectionModel(defaultOption)
        this.selectionModel = defaultOption
      }
    },

    methods: {
      isOffsetViable (option) {
        const duration = this.durations[option]
        if (!duration) {
          return true
        }
        let start = moment(this.dataContext.date_selection.selected_dates[0])
        let end = moment(
          this.dataContext.date_selection.selected_dates[
            this.dataContext.date_selection.selected_dates.length - 1
          ]
        )
        let durationDays = moment(end).diff(moment(start), 'days') + 1
        return duration >= durationDays
      },
      updateSelectionModel (value) {
        this.$set(this.dataContext.control_selections, this.filterMeta.key, {
          type: this.filterMeta.type,
          selected_option: value.key,
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .dropdown-menu {
  padding-top: 4px !important;
  .dropdown-content {
    border-radius: 6px !important;
  }
}
</style>
