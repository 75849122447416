<template>
  <div
    v-if="initialized"
    class="optional-filters">
    <div class="filters-content">
      <div
        v-if="!$screen.is('sm')"
        class="columns desktop-filters is-multiline">
        <div
          v-for="filter in optionalFilters"
          :key="filter.id"
          class="column is-3 is-12-mobile ">
          <CustomFilter
            v-model="localDataContext[filter.key]"
            :filter-meta="filter"
            :fulldata-context="value"
            :dashboard-key="dashboardKey"
            @makeInputsDirty="makeInputsDirty" />
        </div>
      </div>

      <div
        v-else
        class="mobile-filter-container">
        <div
          v-for="filter in optionalFilters"
          :key="filter.id"
          class="mb-4">
          <CustomFilter
            v-model="localDataContext[filter.key]"
            :filter-meta="filter"
            :fulldata-context="value"
            :dashboard-key="dashboardKey"
            @makeInputsDirty="makeInputsDirty" />
        </div>
      </div>
    </div>
    <div class="actions">
      <button
        class="button mr-3 reset-button"
        :class="{ 'is-disabled': !isResetDirty }"
        :disabled="!isResetDirty"
        @click="removeAllFilters">
        Reset
      </button>
      <button
        class="button update-button"
        :class="{ 'is-disabled': !isUpdateDirty }"
        :disabled="!isUpdateDirty"
        @click="updateDataContext">
        Apply
      </button>
    </div>
  </div>
</template>

<script>
  import CustomFilter from './CustomFilter.vue'

  export default {
    name: 'DashboardOptionalFilters',
    components: {
      CustomFilter,
    },
    props: {
      optionalFilters: {
        type: Array,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        localDataContext: {},
        initialized: false,
        isResetDirty: false,
        isUpdateDirty: false,
      }
    },
    mounted () {
      this.optionalFilters.forEach((filter) => {
        this.$set(this.localDataContext, filter.key, null)
      })
      this.initialized = true
    },
    methods: {
      makeInputsDirty () {
        this.isUpdateDirty = true
      },
      updateDataContext () {
        // Format local data context to just have keys
        const formattedLocalDataContext = Object.entries(this.localDataContext)
          .reduce((acc, [key, values,]) => {
            if (values?.length) {
              acc[key] = values.map(value => value.key)
            }
            return acc
          }, {})

        const currentSelections = this.value.filter_selections || {}
        const optionalKeys = this.optionalFilters.map(filter => filter.key)

        // Get required filter selections that aren't optional
        const requiredSelections = Object.entries(currentSelections)
          .reduce((acc, [key, value,]) => {
            if (!optionalKeys.includes(key)) {
              acc[key] = value
            }
            return acc
          }, {})

        // Check if selections are unchanged to prevent unnecessary updates
        const hasChanges = Object.entries(formattedLocalDataContext).some(([key, values,]) => {
          const currentValues = currentSelections[key] || []
          return JSON.stringify(values) !== JSON.stringify(currentValues)
        })

        if (!hasChanges &&
          Object.keys(formattedLocalDataContext).length === Object.keys(currentSelections).length) {
          return
        }

        // Emit updated selections
        this.$emit('input', {
          ...this.value,
          filter_selections: {
            ...formattedLocalDataContext,
            ...requiredSelections,
          },
        })

        // Update dirty states
        this.isResetDirty = Object.keys(formattedLocalDataContext).length > 0
        this.isUpdateDirty = false
      },
      removeAllFilters () {
        Object.keys(this.localDataContext).forEach((key) => {
          this.$set(this.localDataContext, key, null)
        })
        this.updateDataContext()
      },
    },
  }
</script>

<style scoped lang="scss">
.optional-filters {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.filters-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // @include mobile {
  //   flex: 1;
  //   overflow-y: auto;
  //   min-height: 0;
  // }
}

.desktop-filters {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: 0 !important;
  padding: 0 4px!important;

  .column {
    padding: 4px !important;
    margin: 0 !important;
  }
}

.mobile-filter-container {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid #E9E9EA;
  margin-top: 8px;
  padding: 8px;
  // @include mobile {
  //   // flex-direction: row-reverse;
  // }
}

.actions button {
  width: 100px;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;

  &.is-disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  // @include mobile {
  //   width: 100%;
  //   height: 38px;
  // }
}

.reset-button {
  border: 1px solid rgba(255, 119, 89, 0.25) !important;
  color: #ff6341 !important;
  background-color: rgba(255, 99, 65, 0.1) !important;

  &:hover:not(.is-disabled) {
    border: 1px solid rgba(255, 119, 89, 0.5) !important;
    background-color: rgba(255, 99, 65, 0.15) !important;
  }

  &.is-disabled {
    background-color: #f5f5f5 !important;
    border-color: transparent !important;
    color: #d2d2d4 !important;
  }
}

.update-button {
  background-color: #b654b1 !important;
  border-color: transparent !important;
  color: #fff !important;

  &:hover:not(.is-disabled) {
    background-color: #994095 !important;
  }

  &.is-disabled {
    background-color: #f5f5f5 !important;
    border-color: transparent !important;
    color: #d2d2d4 !important;
  }
}
</style>
